<template>
  <div>
    <div class="order-title">
      <h1>
        {{ details.title }}
      </h1>
      <div class="subtitle">
        <span>发布日期：{{ details.createDate || '-' }}</span>
        <span>已报价数：{{ details.qoCount }}</span>
      </div>
    </div>
    <div class="main-content">
      <Row>
        <Col span="8">
          <Row>
            <Col span="4" class="label">品名</Col>
            <Col>{{ details.productName || '-' }}</Col>
          </Row>
        </Col>
        <Col span="8">
          <Row>
            <Col span="4" class="label">材质</Col>
            <Col>{{ details.material || '-' }}</Col>
          </Row>
        </Col>
        <Col span="8">
          <Row>
            <Col span="4" class="label">规格</Col>
            <Col>{{ details.formate || '-' }}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span="8">
          <Row>
            <Col span="4" class="label">型号</Col>
            <Col>{{ details.formate || '-' }}</Col>
          </Row>
        </Col>
        <Col span="8">
          <Row>
            <Col span="4" class="label">计价单位</Col>
            <Col>{{ details.unitName || '-' }}</Col>
          </Row>
        </Col>
        <Col span="8">
          <Row>
            <Col span="4" class="label">仓库</Col>
            <Col>{{ details.warehouseName || '-' }}</Col>
          </Row>
        </Col>
      </Row>
      <Row></Row>
      <Row>求购备注：</Row>
      <Row>
        {{ details.remark || '-' }}
      </Row>
    </div>
    <div class="company-info">
      <div class="info-title">
        <div class="info-left">
          <img :src="details.logo" />
        </div>
        <div class="info-right">
          <div class="right-top">{{ details.shortName }}</div>
          <div class="right-bottom">
            会面码：
            <span style="color: #d12b23">{{ details.meetCode }}</span>
          </div>
        </div>
      </div>
      <div class="info-table">
        <Row class="table-row">
          <Col span="2" class="row-lebel">电话</Col>
          <Col>{{ details.tel }}</Col>
        </Row>
        <Row class="table-row">
          <Col span="2" class="row-lebel">邮箱</Col>
          <Col>{{ details.email }}</Col>
        </Row>
        <Row class="table-row">
          <Col span="2" class="row-lebel">地址</Col>
          <Col>{{ details.currentAddress }}</Col>
        </Row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NeedsListDetail',
  data () {
    return {
      details: {},
    }
  },
  created () {
    const params = {
      id: this.$route.params.id,
    }
    this.$store
      .dispatch('publish/GetPreOrderGoodDetail', params)
      .then((res) => {
        if (res.returnCode === '1') {
          this.details = res.result
        }
      })
  },
}
</script>
<style lang="less" scoped>
.order-title {
  text-align: center;

  .subtitle {
    color: #acacac;
    font-size: 12px;
    text-align: center;

    span {
      padding: 10px 5px;
      display: inline-block;
    }
  }
}

.main-content {
  .label {
    color: #727477;
  }

  /deep/ .ivu-row {
    padding: 8px 0;
  }
}

.company-info {
  padding: 20px 0 60px;

  .info-title {
    display: flex;
    margin-bottom: 20px;

    img {
      width: 80px;
      height: 80px;
      border-radius: 8px;
    }

    .info-left {
      border-radius: 8px;
      box-shadow: 0 0 8px #eeeff2;
      margin-right: 20px;
    }

    .info-right {
      div {
        height: 40px;
        line-height: 40px;
      }

      .right-top {
        font-size: 20px;
        font-weight: 500;
      }

      .right-bottom {
        font-weight: 500;
      }
    }
  }

  .info-table {
    border: 1px solid #dde0e7;
    border-bottom: none;
    border-radius: 8px;

    .table-row {
      height: 40px;
      border-bottom: 1px solid #dde0e7;

      div {
        line-height: 38px;
        padding: 0 20px;
      }

      .row-lebel {
        background-color: #f8f8fb;
        border-right: 1px solid #dde0e7;
      }
    }
  }
}
</style>
